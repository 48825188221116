export function getUrlParams(e) {
  let url = window.location.href;
  let params = url.split('?')[1];
  let paramsObj = {};
  if (params) {
    let paramsArr = params.split('&');
    for (let i = 0; i < paramsArr.length; i++) {
      let param = paramsArr[i].split('=');
      paramsObj[param[0]] = decodeURIComponent(param[1]);
    }
  }
  return paramsObj
}
