<template>
  <div>
    <Header></Header>
    <div class="BigBox">
      <img src="@/assets/errIcon.png" alt="" style="width: 250px;height: 250px;"/>
      <div style="margin: 10px auto;font-size: 1.1rem">{{ errMessage || '发生错误' }}</div>
      <el-button type="primary" @click="backHome()">返回首页</el-button>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {getUrlParams} from "@/utils/tools";

export default {
  name: "errorPage",
  components: {},
  data() {
    return {
      errMessage: ''
    }
  },
  created() {
    // console.log('参数', getUrlParams())
    this.errMessage = getUrlParams().errMsg
  },
  methods: {
    backHome() {
      this.$router.replace('/Index')
    },
  }
}
</script>

<style scoped>
.BigBox {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 68vh;
}
</style>
